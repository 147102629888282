import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {RouteLite} from 'app/blocks/model/site-visit.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IDeliveryPoint, DeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {ICustomer} from 'app/blocks/model/customer.model';
import {DiTime} from 'app/blocks/util/di-time';
import {IInvoice} from 'app/blocks/model/invoice.model';
import {Branch, IBranch} from 'app/blocks/model/branch.model';
import {IFranchise} from 'app/blocks/model/franchise.model';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {ICustomerGroup} from 'app/blocks/model/customer-group.model';
import {ICreditNote} from 'app/blocks/model/credit-note.model';

export interface IApplyPaymentDTO {
    invoice: IInvoice;
    amount: number;
}

export class ApplyPaymentDTO implements IApplyPaymentDTO {
    public invoice: IInvoice;
    public amount: number;
    constructor(invoice?: IInvoice) {
        if (invoice) {
            this.invoice = invoice;
        }
        this.amount = 0;
    }
}

export interface IApplyCreditNotePaymentDTO {
    creditNote: ICreditNote;
    amount: number;
}
export class ApplyCreditNotePaymentDTO implements IApplyCreditNotePaymentDTO {
    public creditNote: ICreditNote;
    public amount: number;
    constructor(creditNote?: ICreditNote) {
        if (creditNote) {
            this.creditNote = creditNote;
        }
        this.amount = 0;
    }
}

// While saving, there is no need to send the whole credit note because it is fetched from the database.
export class SaveCreditNotePaymentDTO {
    public creditNoteId: number;
    public amount: number;
    constructor(creditNotePaymentDTO?: IApplyCreditNotePaymentDTO) {
        if (creditNotePaymentDTO.creditNote) {
            this.creditNoteId = creditNotePaymentDTO.creditNote.id;
        }
        this.amount = creditNotePaymentDTO.amount;
    }
}

export interface IPayment extends IBaseFranchiseEntity {
    id?: number;
    collectedBy?: IEmployee;
    deliveryPoint?: IDeliveryPoint;
    paymentDate?: any;
    paymentTime?: any;
    chequeDate?: any;
    cashAmount?: number;
    cashReference?: string;
    chequeAmount?: number;
    ccAmount?: number;
    ccRef?: string;
    bankPaymentAmount?: number;
    bankPaymentRef?: string;
    totalAmount?: number;
    customer?: ICustomer;
    route?: RouteLite;
    driver?: any;
    chequeNo?: string;
    paidBy?: string;
    reference?: string;
    paymentStatus?: string;
    allocatedAmount?: number;
    invoicesPaid?: IInvoice[];
    creditNoteApplied?: ICreditNote[];
    paymentAllocation?: any[];
    exported?: boolean;
    pgRef?: string;
    pgAmount?: number;
    isPartnerPayment?: boolean;
    partnerCreditAmount?: number;
    unappliedAmount?: number;
    paymentAllocationStatus?: string;
    branch?: IBranch;
    adjustments?: any[];
    customerGroup?: ICustomerGroup;
    sign?: any;
    directDebitRef?: string;
    directDebitAmount?: number;
    approveOnCreateOrUpdate?: boolean;
}

export class Payment extends BaseFranchiseEntity implements IPayment {
    public id?: number;
    public collectedBy?: IEmployee;
    public deliveryPoint?: IDeliveryPoint;
    public paymentDate?: any;
    public chequeDate?: any;
    public paymentTime?: any;
    public cashAmount?: number;
    public cashReference?: string;
    public chequeAmount?: number;
    public ccAmount?: number;
    public ccRef?: string;
    public totalAmount?: number;
    public customer?: ICustomer;
    public route?: RouteLite;
    public driver?: any;
    public chequeNo?: string;
    public paymentStatus?: string;
    public paidBy?: string;
    public reference?: string;
    public allocatedAmount?: number;
    public invoicesPaid?: IInvoice[];
    public creditNoteApplied?: ICreditNote[];
    public paymentAllocation?: any[];
    public exported?: boolean;
    public pgRef?: string;
    public pgAmount?: number;
    public isPartnerPayment?: boolean;
    public partnerCreditAmount?: number;
    public unappliedAmount?: number;
    public branch?: Branch;
    public bankPaymentAmount?: number;
    public bankPaymentRef?: string;
    public paymentAllocationStatus?: string;
    public adjustments?: any[];
    public customerGroup?: ICustomerGroup;
    public sign?: any;
    public directDebitRef?: string;
    public directDebitAmount?: number;
    public approveOnCreateOrUpdate?: boolean;

    constructor(payment?) {
        super();
        if (payment) {
            this.id = payment.id;
            this.collectedBy = payment.collectedBy;
            this.deliveryPoint = new DeliveryPoint(payment.deliveryPoint);
            this.paymentDate = payment.paymentDate;
            this.chequeDate = payment.chequeDate;
            this.chequeAmount = payment.chequeAmount;
            this.paymentTime = payment.paymentTime;
            this.cashAmount = payment.cashAmount;
            this.cashReference = payment.cashReference;
            this.chequeAmount = payment.chequeAmount;
            this.ccAmount = payment.ccAmount;
            this.ccRef = payment.ccRef;
            this.totalAmount = payment.totalAmount;
            this.customer = payment.customer;
            this.route = payment.route || {};
            this.driver = payment.driver;
            this.chequeNo = payment.chequeNo;
            this.paymentStatus = payment.paymentStatus;
            this.paidBy = payment.paidBy;
            this.reference = payment.reference;
            this.allocatedAmount = payment.allocatedAmount || 0;
            this.invoicesPaid = payment.invoicesPaid || [];
            this.creditNoteApplied = payment.creditNoteApplied || [];
            this.paymentAllocation = payment.paymentAllocation || [];
            this.exported = payment.exported;
            this.pgRef = payment.pgRef;
            this.pgAmount = payment.pgAmount;
            this.partnerCreditAmount = payment.partnerCreditAmount;
            this.isPartnerPayment = payment.isPartnerPayment;
            this.unappliedAmount = payment.unappliedAmount;
            this.branch = payment.branch;
            this.editable = payment.editable;
            this.assignedFranchise = payment.assignedFranchise;
            this.bankPaymentAmount = payment.bankPaymentAmount;
            this.bankPaymentRef = payment.bankPaymentRef;
            this.paymentAllocationStatus = payment.paymentAllocationStatus;
            this.adjustments = payment.adjustments;
            this.customerGroup = payment.customerGroup;
            this.sign = payment.sign;
            this.directDebitRef = payment.directDebitRef;
            this.directDebitAmount = payment.directDebitAmount;
            this.approveOnCreateOrUpdate = payment.approveOnCreateOrUpdate;
        } else {
            this.paymentDate = MposDate.currentDate();
            this.paymentTime = DiTime.currentTime();
            this.cashAmount = 0;
            this.chequeAmount = 0;
            this.bankPaymentAmount = 0;
            this.totalAmount = 0;
            this.unappliedAmount = 0;
            this.ccAmount = 0;
            this.allocatedAmount = 0;
            this.invoicesPaid = [];
            this.creditNoteApplied = [];
            this.paymentAllocation = [];
            this.adjustments = [];
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): Payment {
        return new Payment(this);
    }
}
